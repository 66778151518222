<template>
  <v-card outlined elevation="0">
    <v-row class="ma-0">

      <v-toolbar dense color="transparent" elevation="0">
        <router-link :to="'/admin/create-entity/commande/' + commande._id" style="text-decoration:none">
          <small :class="colorStatus + '--text'"><v-icon small :color="colorStatus">mdi-star-outline</v-icon> n°{{ commande._id }}</small>
        </router-link>
        <v-spacer/>
        <v-btn :to="'/admin/create-entity/commande/' + commande._id"
                elevation="0" icon small class="mr-0"
                title="Afficher / éditer la commande">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>

      <v-col cols="12" sm="12" class="py-0 px-4">
        <b>{{ commande.name }}</b><br>
        <small>{{ commande.email }} | <b>{{ commande.price }}€</b></small><br>
        <v-divider class="mt-3"/>
      </v-col>

      <v-col cols="3" sm="3" md="3" class="text-center px-4">
        <template v-if="commande.colorRef == 'exists'">
          <v-img :src="baseUrl() + commande.productRef.image" :aspect-ratio="1" 
                width="100%" v-if="commande.colorRef == 'exists'" class="mt-2"/>
        </template>
        <template v-else>
          <!-- <v-img src="@/assets/img/commande/back-air.jpg"   class="img-rounded btn-element mt-2" :aspect-ratio="1/1" width="100%" v-if="commande.element=='air'"/>
          <v-img src="@/assets/img/commande/back-feu.jpg"   class="img-rounded btn-element mt-2" :aspect-ratio="1/1" width="100%" v-if="commande.element=='feu'"/>
          <v-img src="@/assets/img/commande/back-terre.jpg" class="img-rounded btn-element mt-2" :aspect-ratio="1/1" width="100%" v-if="commande.element=='terre'"/>
          <v-img src="@/assets/img/commande/back-eau.jpg"   class="img-rounded btn-element mt-2" :aspect-ratio="1/1" width="100%" v-if="commande.element=='eau'"/>
          <v-img src="@/assets/img/commande/back-ether.jpg" class="img-rounded btn-element mt-2" :aspect-ratio="1/1" width="100%" v-if="commande.element=='ether'"/> -->
        </template>
      </v-col>

      <v-col cols="9" sm="9" class="mt-1">
        Support : <b>{{ commande.support }}</b><br>
        Taille : <b>{{ commande.size }} cm</b><br>

        <template v-if="commande.colorRef == 'exists'">
          Inspi : <b>{{ commande.productRef.name }}</b><br>
        </template>
        <template v-else>
          Élement : <b style="text-transform:capitalize">{{ commande.element }}</b><br>
        </template>
        
        <b class="font-flower" style="font-size:22px;">
          {{ commande.birthday }} 
          - Chiffre
          {{ commande.chiffre }}
        </b>

      </v-col>

      <v-col cols="12">
        <v-alert outlined  border="left" v-if="commande.message != null && commande.message.length > 0"
                  color="grey" dense >
              <small>"{{ commande.message }}"</small>
        </v-alert> 

        

      </v-col>

      <v-col cols="12" md="6" class="text-left">
          <small>
              <v-divider/>
              Frais port : {{ commande.fraisDePort }}€
              <v-divider/>
              Création : {{ commande.price }}€
              <v-divider/>
              Acompte : {{ commande.price * 20 / 100 }}€
          </small>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <small>
              <v-divider/>
              Total commande : {{ commande.totalPrice }}€
              <v-divider/>
              Total payé : {{ commande.totalPaid }}€
              <v-divider/>
              Reste à payer : <b>{{ commande.totalPrice - commande.totalPaid }}€</b>
          </small>
        </v-col>

    </v-row>

    <v-divider/>

    <v-toolbar dense color="transparent" elevation="0">

      <v-btn dark small color="blue" class="" outlined
               v-if="commande.status=='OPEN'"
               @click="sendMailAccompte()"
               elevation="0">
          <v-icon small class="mr-1">mdi-email</v-icon>
          Renvoyer @acompte
        </v-btn>
        <v-btn dark small color="teal" class="" outlined
               v-if="commande.status=='PREPAID'"
               @click="sendMailPaiement()"
               elevation="0">
          <v-icon small class="mr-1">mdi-email</v-icon>
          Envoyer @paiement
        </v-btn>
        <v-btn dark small color="amber" class="" outlined
               v-if="commande.status=='READY'"
               @click="sendMailPaiement()">
          <v-icon small class="mr-1">mdi-email</v-icon>
          Renvoyer email paiement
        </v-btn>


      <v-spacer/>
      <v-btn text @click="cancelCommande()" color="red" v-if="commande.status != 'PAID'">Annuler la commande</v-btn>
      <v-btn text @click="sentCommande()" color="green" v-if="commande.status == 'PAID'">Commande expédiée</v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>

import core from '../..//../plugins/core.js'
import axios from 'axios'

//import { i18n } from 'vue-lang-router';

export default {
    name: "card-commande",
    components: {
    },
    props: ['commande'],
    data: function () {
        return {
        };
    },
    mounted: function(){
    },
    methods: {
        async sendMailAccompte(){ 
          let res = await axios.post('/commande/send-mail-accompte/' + this.commande._id)
          if(res.data.error == false){
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Le mail d'acompte a bien été envoyé",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async sendMailPaiement(){
          let res = await axios.post('/commande/send-mail-paiement/' + this.commande._id)
          if(res.data.error == false){
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Le mail de paiement a bien été envoyé",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async cancelCommande(){ 
          let res = await axios.post('/commande/cancel/' + this.commande._id)
          if(res.data.error == false){
            this.$root.$emit('reloadCommandes')
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "La commande a bien été annulée",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async sentCommande(){
          let res = await axios.post('/commande/sent/' + this.commande._id)
          if(res.data.error == false){
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Commande envoyée ! " + this.commande.totalPrice + "€ dans la besace !",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'red', 
                                  title: "Une erreur est survenue pendant l'opération",
                                  text: res.data.msg,
                                  icon: 'alert' })
          }
        },
        baseUrl(){ return core.baseUrl() },
        showNotifError(msg){
          this.$store.dispatch('app/openSnackbar', 
                      { show: true, color:'red', 
                        title: "Une erreur est survenue pendant l'opération",
                        text: msg, icon: 'alert' })
        }
    },
    computed:{
      colorStatus(){ 
        if(this.commande.status == 'OPEN') return 'blue'
        if(this.commande.status == 'PREPAID') return 'teal'
        if(this.commande.status == 'READY') return 'amber'
        if(this.commande.status == 'PAID') return 'green'
        return 'grey'
      }
    }
};
</script>